import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import About from "./screens/About";
import Services from "./screens/Services";
import Careers from "./screens/Careers";
import Contact from "./screens/Contact";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import GoToTopButton from "./components/GoToTopButton";
const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="about-us" element={<About />} />
          <Route exact path="services" element={<Services />} />
          <Route exact path="careers" element={<Careers />} />
          <Route exact path="contact" element={<Contact />} />
        </Routes>
      </Router>
      {/* <FloatingWhatsApp 
        phoneNumber="+919730883780" // Change to your desired phone number
        accountName=" SZReg Pharma" // Set your desired account name
        avatar="https://www.szregpharma.com/api/support.svg"
        statusMessage="Right place for all pharma concerns" // Customize the status message
        chatMessage="Hi there! How can we help you?" // Default message
        allowEsc // Allows closing the widget with the Escape key
        allowClickAway // Allows closing the widget by clicking outside of it
      /> */}
      <GoToTopButton/>
    </div>
  );
};

export default App;
