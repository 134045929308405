import React, { useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ExperienceCounter from "../components/ExperienceCounter";
import { PiArrowFatLineDown } from "react-icons/pi";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import EnquiryForm from "../components/EnquiryForm";
import CountUp from 'react-countup';

const Home = () => {
  const [show, setShow] = useState(false);

  // Handlers to open and close the modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const nextSectionRef = useRef(null);

  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div>
      <div>
        <div className="banner">
          <Header />
          <div className="container">
            <div className="banner-content">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 text-center">
                  <p>Welcome To SZReg Pharma</p>
                  <h1>
                    You are just one step away from your product get registered.
                  </h1>
                  <h2>
                    Let us help you streamline the process with ease and
                    efficiency.
                  </h2>
                  <div className="topline"></div>
                  {/* <div className="middleline"></div>
                <div className="bottomeline"></div> */}
                </div>
              </div>
            </div>
            <div className="get-started" onClick={scrollToNextSection}>
              <PiArrowFatLineDown />
            </div>
          </div>
        </div>
        <section className="section welcome" ref={nextSectionRef}>
          <div className="container">
            <div className="row gx-5">
              <div className="col-12 col-md-6">
                <div className="welcome-img mb-3">
                  {/* <Image
                  src="/images/welcome-1.webp"
                  alt="Logo"
                  
                  width={}
                  height={}
                  className="main-logo"
                  quality={100}
                /> */}
                  <img src="images/welcome-1.webp" className="img-fluid" />
                  <div className="experience-card text-center">
                    <ExperienceCounter />
                  </div>
                </div>
                <p>
                  Welcome to SZRegPharma. We offer expert services in Regulatory
                  Affairs, Pharma Artwork Design, Medical Writing, and
                  Pharmacovigilance. Trusted by clients worldwide for reliable,
                  compliant, and timely solutions.
                </p>
              </div>
              <div className="col-12 col-md-6">
                <h2 className="sub-title">About our Company</h2>
                <h1 className="main-title">
                  {" "}
                  Right place for all pharma concerns
                </h1>
                <div className="welcome-img mt-2">
                  <img src="images/welcome-2.webp" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section bg-light">
      <div className="container">
        <div className="row gx-5">
          <div className="col-12 col-md-12 text-center">
            <h2 className="sub-title">Our Key Achievements</h2>
            <p>Our achievements speak for our commitment to excellence</p>
          </div>
        </div>
        <div className="row gx-3 gy-2">
          <div className="col-12 col-md-3">
            <div className="achievement-card text-center">
              <img
                src="images/icons/experience.svg"
                className="img-fluid"
                alt="Years of Experience"
              />
              <h3>
                <CountUp end={15} duration={15} />
              </h3>
              <h5>Years of Experience</h5>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="achievement-card text-center">
              <img
                src="images/icons/client.svg"
                className="img-fluid"
                alt="Happy Clients"
              />
              <h3>
                <CountUp end={50} suffix="+" duration={15} />
              </h3>
              <h5>Happy Clients</h5>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="achievement-card text-center">
              <img
                src="images/icons/countries.svg"
                className="img-fluid"
                alt="Countries"
              />
              <h3>
                <CountUp end={25} suffix="+" duration={15} />
              </h3>
              <h5>Countries</h5>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="achievement-card text-center">
              <img
                src="images/icons/approved-product.svg"
                className="img-fluid"
                alt="Product Approvals"
              />
              <h3>
                <CountUp end={100} suffix="+" duration={15} />
              </h3>
              <h5>Product Approvals</h5>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="achievement-card text-center">
              <img
                src="images/icons/file.svg"
                className="img-fluid"
                alt="Dossier Reviewed"
              />
              <h3>
                <CountUp end={500} suffix="+" duration={15} />
              </h3>
              <h5>Dossier Reviewed</h5>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="achievement-card text-center">
              <img
                src="images/icons/chemistry.svg"
                className="img-fluid"
                alt="DMF Reviewed"
              />
              <h3>
                <CountUp end={750} suffix="+" duration={15} />
              </h3>
              <h5>DMF Reviewed</h5>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="achievement-card text-center">
              <img
                src="images/icons/graphics.svg"
                className="img-fluid"
                alt="Artwork Designed"
              />
              <h3>
                <CountUp end={1000} suffix="+" duration={15} />
              </h3>
              <h5>Artwork Designed</h5>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="achievement-card text-center">
              <img
                src="images/icons/open-book.svg"
                className="img-fluid"
                alt="Publications"
              />
              <h3>
                <CountUp end={10} suffix="+" duration={15} />
              </h3>
              <h5>Publications</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
        <div className="cta">
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10">
                <h1>
                  Partner with SZRegPharma for reliable, compliant, and timely
                  pharmaceutical solutions. Contact us today to streamline your
                  regulatory needs and ensure excellence in every step!
                </h1>
                <br />
                <Link to="contact">
                  <button className="btn btn-contact">Contact Us</button>
                </Link>

                <button className="btn btn-enquiry" onClick={handleShow}>
                  Enquiry Now
                </button>
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Enquiry Form</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <EnquiryForm />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <section className="section welcome bg-light">
          <div className="container">
            <div className="row gx-5">
              <div className="col-12 col-md-12 text-center">
                <h2 className="sub-title mt-2">Why Choose Us?</h2>
              </div>
            </div>
            <div className="row gx-4 gy-4 mt-3">
              <div className="col-12 col-md-3 text-center">
                <div className="achievements-card">
                  <img src="images/about/quality.webp" className="img-fluid" />
                  <h4>We provide 100% quality services</h4>
                  <p>
                    Our commitment to excellence ensures that every project we
                    deliver meets the highest standards of quality and
                    professionalism.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 text-center">
                <div className="achievements-card">
                  <img src="images/about/secured.webp" className="img-fluid" />
                  <h4>We keep our customer data secured</h4>
                  <p>
                    Your trust is our priority. We implement robust security
                    measures to safeguard your sensitive data at every step.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 text-center">
                <div className="achievements-card">
                  <img
                    src="images/about/relationship.webp"
                    className="img-fluid"
                  />
                  <h4>We believe in long-term relationships</h4>
                  <p>
                    Building lasting partnerships is at the core of our values.
                    We aim to earn your trust and loyalty through consistent
                    performance and support.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 text-center">
                <div className="achievements-card">
                  <img src="images/about/customer.webp" className="img-fluid" />
                  <h4>We provide 24x7 customer support</h4>
                  <p>
                    Your convenience matters to us. Our dedicated support team
                    is available around the clock to assist you whenever you
                    need us.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 text-center">
                <div className="achievements-card">
                  <img
                    src="images/about/deadlines.webp"
                    className="img-fluid"
                  />
                  <h4>We care about client deadlines</h4>
                  <p>
                    Timely delivery is essential. We are committed to meeting
                    your deadlines without compromising on quality.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 text-center">
                <div className="achievements-card">
                  <img src="images/about/staff.webp" className="img-fluid" />
                  <h4>Our staff is experienced in the said matter</h4>
                  <p>
                    Our team comprises skilled professionals with extensive
                    expertise, ensuring that your project is handled by capable
                    hands.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 text-center">
                <div className="achievements-card">
                  <img
                    src="images/about/confidentiality.webp"
                    className="img-fluid"
                  />
                  <h4>We keep our customer confidentiality </h4>
                  <p>
                    Confidentiality is non-negotiable. We handle all client
                    information with utmost discretion and care.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 text-center">
                <div className="achievements-card">
                  <img src="images/about/need.webp" className="img-fluid" />
                  <h4>We understand & fulfill our client needs</h4>
                  <p>
                    Your satisfaction drives us. We take the time to understand
                    your unique requirements and tailor our solutions to meet
                    them effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
