import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    organization: "",
    service: "",
    customService: "", // Add state for custom service
    message: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    if (
      !formData.fullName ||
      !formData.email ||
      !formData.phone ||
      !formData.service ||
      !formData.message
    ) {
      alert("All fields are required");
      setIsSending(false);
      return;
    }

    // Include the custom service if "Other" is selected
    const dataToSend = formData.service === "Other"
      ? { ...formData, service: formData.customService }
      : formData;

    try {
      const response = await axios.post(
        "https://www.szregpharma.com/api/enq_mail.php",
        dataToSend,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      console.log("Server response:", response.data);

      if (response.data.success) {
        setIsSent(true);
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          organization: "",
          service: "",
          customService: "",
          message: "",
        });
      } else {
        alert("Failed to send email. Please try again.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="enquiry-form">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-2" controlId="fullName">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your full name"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="email">
          <Form.Label>Email ID</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Enter your phone number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="organization">
          <Form.Label>Name of Organization</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the name of your organization"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="service">
          <Form.Label>Services</Form.Label>
          <Form.Select
            name="service"
            value={formData.service}
            onChange={handleChange}
            required
          >
            <option value="">Select a service</option>
            <option value="Regulatory Affairs">Regulatory Affairs</option>
            <option value="Artwork Design">Artwork Design</option>
            <option value="Pharmacovigilance">Pharmacovigilance</option>
            <option value="Medical Writing">Medical Writing</option>
            <option value="Other">Other</option> {/* Add Other option */}
          </Form.Select>
        </Form.Group>

        {/* Conditionally render the custom service input */}
        {formData.service === "Other" && (
          <Form.Group className="mb-2" controlId="customService">
            <Form.Label>Custom Service</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your custom service"
              name="customService"
              value={formData.customService}
              onChange={handleChange}
            />
          </Form.Group>
        )}

        <Form.Group className="mb-2" controlId="message">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter your message"
            name="message"
            className="h-100"
            value={formData.message}
            onChange={handleChange}
          />
        </Form.Group>

        <Button className="btn btn-submit" type="submit" disabled={isSending}>
          {isSending ? "Sending..." : "Submit"}
        </Button>

        {isSent && (
          <p className="text-success mt-2">Email sent successfully!</p>
        )}
      </Form>
    </div>
  );
};

export default EnquiryForm;
