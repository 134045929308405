import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { LuArrowBigRightDash } from "react-icons/lu";
const About = () => 
    <div>
      <div className="about-banner">
        <Header />
        <div className="inner-banner">
          <div className="container">
            <div className="row text-center">
              <h1>About Us</h1>
              <p>
                We are a company committed to delivering exceptional services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="section welcome">
        <div className="container">
          <div className="row gx-5">
            <div className="col-12 col-md-7">
              <br />
              <h2 className="sub-title">Who We Are?</h2>
              <p>
                With over 15+ years of experience in the pharmaceutical
                industry, we specialize in delivering tailored solutions for
                Regulatory Affairs, Pharma Artwork Design, Medical Writing, and
                Pharmacovigilance. Our mission is to ensure your success by
                simplifying complex processes and providing top-notch service.
              </p>
              <br />
              <h2 className="sub-title mt-2">Key Highlights</h2>
              <ul>
                <li> <span><LuArrowBigRightDash /></span>  Trusted by clients in 25+ countries.</li>
                <li>
                <span><LuArrowBigRightDash /></span> Expertise in handling end-to-end regulatory submissions and
                  compliance.
                </li>
                <li><span><LuArrowBigRightDash /></span> Proven track record of quality and precision. </li>
              </ul>
            </div>
            <div className="col-12 col-md-5">
              <div className="welcome-img mb-3">
                <img src="images/welcome-1.webp" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section welcome bg-light">
        <div className="container">
          <div className="row gx-5">
            <div className="col-12 col-md-12 text-center">
              <h2 className="sub-title mt-2">Why Choose Us?</h2>
            </div>
          </div>
          <div className="row gx-4 gy-4 mt-3">
            <div className="col-12 col-md-3 text-center">
              <div className="achievements-card">
                <img src="images/about/quality.webp" className="img-fluid" />
                <h4>We provide 100% quality services</h4>
                <p>
                  Our commitment to excellence ensures that every project we
                  deliver meets the highest standards of quality and
                  professionalism.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="achievements-card">
                <img src="images/about/secured.webp" className="img-fluid" />
                <h4>We keep our customer data secured</h4>
                <p>
                Your trust is our priority. We implement robust security measures to safeguard your sensitive data at every step.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="achievements-card">
                <img src="images/about/relationship.webp" className="img-fluid" />
                <h4>We believe in long-term relationships</h4>
                <p>
                Building lasting partnerships is at the core of our values. We aim to earn your trust and loyalty through consistent performance and support.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="achievements-card">
                <img src="images/about/customer.webp" className="img-fluid" />
                <h4>We provide 24x7 customer support</h4>
                <p>
                Your convenience matters to us. Our dedicated support team is available around the clock to assist you whenever you need us.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="achievements-card">
                <img src="images/about/deadlines.webp" className="img-fluid" />
                <h4>We care about client deadlines</h4>
                <p>
                Timely delivery is essential. We are committed to meeting your deadlines without compromising on quality.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="achievements-card">
                <img src="images/about/staff.webp" className="img-fluid" />
                <h4>Our staff is experienced in the said matter</h4>
                <p>
                Our team comprises skilled professionals with extensive expertise, ensuring that your project is handled by capable hands.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="achievements-card">
                <img src="images/about/confidentiality.webp" className="img-fluid" />
                <h4>We keep our customer confidentiality                </h4>
                <p>
                Confidentiality is non-negotiable. We handle all client information with utmost discretion and care.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-3 text-center">
              <div className="achievements-card">
                <img src="images/about/need.webp" className="img-fluid" />
                <h4>We understand & fulfill our client needs</h4>
                <p>
                Your satisfaction drives us. We take the time to understand your unique requirements and tailor our solutions to meet them effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

    </div>;

export default About;
