import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

const Services = () => <div>
<div className="about-banner">
  <Header />
  <div className="inner-banner">
    <div className="container">
      <div className="row text-center">
        <h1>Our Services</h1>
        <p>
          We are a company committed to delivering exceptional services.
        </p>
      </div>
    </div>
  </div>
</div>
<section className="section welcome">
  <div className="container">
    <div className="row gx-5">
      <div className="col-12 col-md-6 order-2 order-md-1">
        <h2 className="sub-title">Regulatory Affairs:</h2>
        <p>
          SZReg Pharma is a trusted partner in providing end-to-end
          regulatory services for both regulated and non-regulated
          markets. Our expertise lies in navigating the complexities of
          regulatory frameworks to deliver precise, high-quality results
          tailored to the unique requirements of each market. With a deep
          understanding of global and local regulatory standards, we
          ensure that our clients' products meet all compliance criteria
          efficiently and effectively.
        </p>
        <p>
          Our commitment to excellence is reflected in our 100% success
          rate, achieved through meticulous planning, thorough
          documentation, and proactive communication with regulatory
          authorities. By prioritizing speed and accuracy, we help our
          clients gain swift market access, enabling them to stay ahead in
          the competitive pharmaceutical landscape. At SZReg Pharma, we
          turn regulatory challenges into opportunities for success,
          fostering growth and trust with every project.
        </p>
      </div>
      <div className="col-12 col-md-6 order-1 order-md-2">
        <div className="welcome-img mb-3">
          <img
            src="images/services/regulatory.jpg"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<section className="section welcome bg-light">
  <div className="container">
    <div className="row gx-5">
      <div className="col-12 col-md-6">
        <div className="welcome-img mb-3">
          <img src="images/services/artwork.jpg" className="img-fluid" />
        </div>
      </div>
      <div className="col-12 col-md-6">
        <h2 className="sub-title">Artwork Design:</h2>
        <p>
          SZReg Pharma offers comprehensive pharmaceutical labeling
          services, expertly designed to meet the regulatory and
          communication needs of the healthcare industry. Our services
          cover every aspect of label development, including content
          creation, design, artwork review, and compliance checks,
          ensuring adherence to global standards such as FDA, EMA, and
          other regulatory bodies. We focus on creating labels that
          clearly convey essential product information—such as dosage
          instructions, safety warnings, and storage requirements—to both
          healthcare professionals and patients.
        </p>
        <p>
          Our artwork review process ensures that every detail, from
          layout to readability, aligns with regulatory guidelines while
          maintaining a user-friendly design. By prioritizing clarity and
          accuracy, we help reduce the risk of errors and enhance the
          accessibility of critical information. With a commitment to
          precision and quality, SZReg Pharma ensures that your product
          labels not only meet compliance requirements but also foster
          trust and confidence among users.
        </p>
      </div>
    </div>
  </div>
</section>

<section className="section welcome">
  <div className="container">
    <div className="row gx-5">
      <div className="col-12 col-md-6 order-2 order-md-1">
        <h2 className="sub-title">Pharmacovigilance</h2>
        <p>
        SZReg Pharma possesses advanced technical expertise to ensure the delivery of high-quality and dependable safety solutions while minimizing associated risks. The company offers a comprehensive suite of Pharmacovigilance services designed to enhance drug safety and compliance throughout the product lifecycle. These services include:
        </p>
        <p>
        <b>Risk Management:</b> Implementing proactive strategies to identify, assess, and mitigate potential risks associated with pharmaceuticals, ensuring patient safety and regulatory compliance.
        </p>
        <p>
        <b>Drug Safety Surveillance:</b> Conducting continuous monitoring and evaluation of drug safety profiles to detect adverse events and maintain the benefit-risk balance.
        </p>
        <p>
        <b>Post-Marketing Activities:</b> Managing post-approval safety processes, including adverse event reporting, periodic safety updates, and real-world evidence collection to support long-term product safety and efficacy.
        </p>
      </div>
      <div className="col-12 col-md-6 order-1 order-md-2">
        <div className="welcome-img mb-3">
          <img
            src="images/services/pharmacovigilance.jpg"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<section className="section welcome bg-light">
  <div className="container">
    <div className="row gx-5">
      <div className="col-12 col-md-6">
        <div className="welcome-img mb-3">
          <img src="images/services/writing.webp" className="img-fluid" />
        </div>
      </div>
      <div className="col-12 col-md-6">
        <h2 className="sub-title">Medical Writing</h2>
        <p>
          <b>SZReg Pharma: Enhancing Medical Communication and Patient Safety</b>
        </p>
        <p>
        SZReg Pharma's expert medical writers bridge the gap between complex medical data and practical application. By delivering accurate and clear content, they improve communication among healthcare professionals, aiding informed decision-making. Committed to patient safety, our writers ensure all materials meet rigorous quality standards, fostering trust and optimal care outcomes.
        </p>
      </div>
    </div>
  </div>
</section>
<Footer />


</div>;

export default Services;
