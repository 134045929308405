'use client'; // This marks the component as a client-side component

import { useEffect, useRef, useState } from 'react';
import styles from './ExperienceCounter.module.css'; // Import CSS module

const ExperienceCounter = () => {
  const counterRef = useRef(null);
  const [inView, setInView] = useState(false);
  const [displayCount, setDisplayCount] = useState(0);

  // Define the starting year of experience
  const startingYear = 2009; // Replace with your actual starting year
  const currentYear = new Date().getFullYear();
  const experienceYears = currentYear - startingYear;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is in view
      }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let timer;
    if (inView && displayCount < experienceYears) {
      timer = setInterval(() => {
        setDisplayCount((prevCount) => Math.min(prevCount + 1, experienceYears)); // Increment up to the calculated years
      }, 100); // Adjust the speed of the count-up
    } else if (inView && displayCount === experienceYears) {
      clearInterval(timer); // Stop the interval once the target is reached
    }
    return () => clearInterval(timer); // Cleanup on component unmount
  }, [inView, displayCount, experienceYears]);

  return (
    <div className="experience-content">
      <p ref={counterRef} className={inView ? styles.animate : ''}>
        <span className={styles.counter}>{displayCount}+</span> <br /> Years of Experience
      </p>
    </div>
  );
};

export default ExperienceCounter;
