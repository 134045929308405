import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    organization: "",
    services: "",
    customService: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    // Validate form data
    let formErrors = {};
    if (!formData.firstName) formErrors.firstName = "First Name is required";
    if (!formData.lastName) formErrors.lastName = "Last Name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (!formData.contactNumber) formErrors.contactNumber = "Contact Number is required";
    if (!formData.services) formErrors.services = "Service selection is required";
    if (!formData.message) formErrors.message = "Message is required";
    setErrors(formErrors);
  
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await axios.post(
          "https://www.szregpharma.com/api/send_email.php", // Update to match your PHP file path
          {
            ...formData,
            services: formData.services === "Other" ? formData.customService : formData.services,
          }
        );
        if (response.data.status === "success") {
          setResponseMessage("Message sent successfully!");
  
          // Clear the form fields after successful submission
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            contactNumber: "",
            organization: "",
            services: "",
            customService: "",
            message: "",
          });
  
          // Optionally, clear errors as well
          setErrors({});
        } else {
          setResponseMessage(response.data.message || "Failed to send the message.");
        }
      } catch (error) {
        setResponseMessage("An error occurred while sending the message.");
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div>
      <div className="about-banner">
        <Header />
        <div className="inner-banner">
          <div className="container">
            <div className="row text-center">
              <h1>Contact Us</h1>
              <p>
                We are a company committed to delivering exceptional services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="section welcome">
        <div className="container">
          <div className="row gx-5">
            <div className="col-12 col-md-6">
              <div className="contact-card">
                <h5>Let’s Work Together!</h5>
                <p>
                  We’re here to help you succeed. Reach out to discuss your
                  project requirements and let us take care of the rest
                </p>
                <h6>Address</h6>
                <p>
                  Riyadh, Saudi Arabia <br />
                  Mumbai, India
                </p>
                <h6>Drop a mail</h6>
                <p>
                  <a href="mailto:contact@szregpharma.com">
                    contact@szregpharma.com
                  </a>{" "}
                  <br />
                  <a href="mailto:szregpharma@gmail.com">
                    szregpharma@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-card">
                <h4>Get in Touch:</h4>
                <form onSubmit={handleSubmit}>
                  <div className="row gx-2 gy-2">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          name="firstName"
                          className="form-control"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && <small>{errors.firstName}</small>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          className="form-control"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        {errors.lastName && <small>{errors.lastName}</small>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && <small>{errors.email}</small>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Contact Number</label>
                        <input
                          type="text"
                          name="contactNumber"
                          className="form-control"
                          value={formData.contactNumber}
                          onChange={handleChange}
                        />
                        {errors.contactNumber && (
                          <small>{errors.contactNumber}</small>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Name of Organization</label>
                        <input
                          type="text"
                          name="organization"
                          className="form-control"
                          value={formData.organization}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Services</label>
                        <select
                          name="services"
                          className="form-select"
                          value={formData.services}
                          onChange={handleChange}
                        >
                          <option value="">Select a service</option>
                          <option value="Regulatory Affairs">
                            Regulatory Affairs
                          </option>
                          <option value="Artwork Design">Artwork Design</option>
                          <option value="Pharmacovigilance">
                            Pharmacovigilance
                          </option>
                          <option value="Medical Writing">
                            Medical Writing
                          </option>
                          <option value="Other">Other</option>{" "}
                          {/* Add 'Other' option */}
                        </select>
                        {errors.services && <small>{errors.services}</small>}
                      </div>
                    </div>

                    {/* Conditionally render custom service input */}
                    {formData.services === "Other" && (
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label>Custom Service</label>
                          <input
                            type="text"
                            name="customService"
                            className="form-control"
                            placeholder="Enter Service Name"
                            value={formData.customService}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>Message</label>
                        <textarea
                          name="message"
                          value={formData.message}
                          className="form-control h-100"
                          onChange={handleChange}
                        ></textarea>
                        {errors.message && <small>{errors.message}</small>}
                      </div>
                    </div>

                    <div className="col-12 col-md-6"></div>
                  </div>

                  <button
                    className="btn btn-submit"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Sending..." : "Send Message"}
                  </button>
                  {responseMessage && <p>{responseMessage}</p>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
