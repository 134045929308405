import React, { useState, useEffect } from 'react';
import { MdOutlineKeyboardArrowUp } from "react-icons/md";

const GoToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle the visibility of the button
  const handleScroll = () => {
    if (window.scrollY > 300) { // Button appears after scrolling down 300px
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Attach the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      onClick={scrollToTop}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#2105A2',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '0px 10px 2px',
        fontSize: '26px',
        display: isVisible ? 'block' : 'none',
        cursor: 'pointer',
      }}
    >
      <MdOutlineKeyboardArrowUp />
    </button>
  );
};

export default GoToTopButton;
