"use client";
import { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from 'axios';

export default function Career() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    qualification: '',
    experience: '',
    address: '',
    message: '',
  });

  const [resume, setResume] = useState(null); // State for storing the uploaded file
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setResume(e.target.files[0]); // Save the uploaded file to the state
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    // Append all form data fields
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    if (resume) {
      formDataToSend.append('resume', resume); // Append the resume file
    }

    // Sending form data using axios
    axios.post('https://www.szregpharma.com/api/career.php', formDataToSend, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the proper header for file uploads
      },
    })
      .then((response) => {
        setStatus('Form submitted successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          contact: '',
          qualification: '',
          experience: '',
          address: '',
          message: '',
        });
        setResume(null); // Reset the resume input
      })
      .catch((error) => {
        setStatus('There was an error submitting the form.');
      });
  };

  return (
    <div>
      {/* Banner Section */}
      <div className="about-banner">
        <Header />
        <div className="inner-banner">
          <div className="container">
            <div className="row text-center">
              <h1>Join Our Team</h1>
              <p>
                Interested in being a part of our team? Fill out the form below,
                and we’ll get in touch!
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Form Section */}
      <section className="section welcome main-form">
        <div className="container">
          <div className="row gx-5 align-items-center">
            <div className="col-12 col-md-6">
              <div className="form-card">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <label htmlFor="firstName" className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="lastName" className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label htmlFor="contact" className="form-label">Contact No.</label>
                      <input
                        type="text"
                        className="form-control"
                        id="contact"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label htmlFor="qualification" className="form-label">Highest Qualification</label>
                      <select
                        className="form-select"
                        id="qualification"
                        name="qualification"
                        value={formData.qualification}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="High School">High School</option>
                        <option value="Bachelor's">Bachelor's</option>
                        <option value="Master's">Master's</option>
                        <option value="PhD">PhD</option>
                      </select>
                    </div>

                    <div className="col-12 col-md-6">
                      <label htmlFor="experience" className="form-label">Work Experience</label>
                      <select
                        className="form-select"
                        id="experience"
                        name="experience"
                        value={formData.experience}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Fresher">Fresher</option>
                        <option value="1-5 Years">1 - 5 Years</option>
                        <option value="6-10 Years">6 - 10 Years</option>
                        <option value="11+ Years">11+ Years</option>
                      </select>
                    </div>

                    <div className="col-12 col-md-12">
                      <label htmlFor="address" className="form-label">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12 col-md-12">
                      <label htmlFor="message" className="form-label">Message</label>
                      <textarea
                        className="form-control h-100"
                        id="message"
                        name="message"
                        rows="3"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="col-12 col-md-12 mt-5 upload-resume">
                      <label htmlFor="resume" className="form-label">Upload Resume</label>
                      <input
                        type="file"
                        className="form-control"
                        id="resume"
                        name="resume"
                        onChange={handleFileChange}
                        accept=".pdf,.doc,.docx"
                        required
                      />
                    </div>
                  </div>

                  <br />
                  <button type="submit" className="btn btn-submit mt-2">Submit</button>
                </form>
                {status && <p className="mt-3">{status}</p>}
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="welcome-img">
                <img src="images/join.webp" alt="Career Opportunities" className="img-fluid rounded" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
