import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  NavDropdown,
  Button,
} from "react-bootstrap";
import {
  FiMail,
  FiLinkedin,
  FiYoutube,
  FiInstagram,
  FiTwitter,
} from "react-icons/fi";
import { RiTwitterXFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import EnquiryForm from "../components/EnquiryForm";
const Header = () => {
  const [show, setShow] = useState(false);

  // Handlers to open and close the modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <header>
      <div className="top-header">
        <Container>
          <Row className="justify-content-center align-items-center g-2">
            <Col md={6}>
              <p className="hide-mobile">Welcome To SZReg Pharma</p>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              <ul className="top-link mb-0">
              <li>
                  <a
                    href="mailto:contact@szregpharma.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center me-3"
                  >
                    <FiMail />{" "}
                    <span className="ms-1">contact@szregpharma.com</span>
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:szregpharma@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex align-items-center me-3"
                  >
                    <FiMail />{" "}
                    <span className="ms-1">szregpharma@gmail.com</span>
                  </a>
                </li>
                
                <li>
                  <a
                    href="https://www.linkedin.com/in/szreg-pharma-a81503263/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="me-3"
                  >
                    <FiLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@szregpharma"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="me-3"
                  >
                    <FiYoutube />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/szreg_pharma/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="me-3"
                  >
                    <FiInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://x.com/SZReg_Pharma"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <RiTwitterXFill />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Navbar expand="lg" className="main-header">
          <Container>
            <Navbar.Brand href="/">
              <img
                src="/images/logo.jpg"
                alt="Logo"
                className="main-logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarNav" />
            <Navbar.Collapse id="navbarNav">
              <Nav className="ms-auto">
                <Nav.Link as={NavLink} to="/" exact activeClassName="active">
                  Home
                </Nav.Link>
                <Nav.Link as={NavLink} to="/about-us" activeClassName="active">
                  About
                </Nav.Link>
                <Nav.Link as={NavLink} to="/services" activeClassName="active">
                  Services
                </Nav.Link>
                <Nav.Link as={NavLink} to="/careers" activeClassName="active">
                  Careers
                </Nav.Link>
                <Nav.Link as={NavLink} to="/contact" activeClassName="active">
                  Contact
                </Nav.Link>
                {/* Uncomment if Enquiry button is needed */}
                <Nav.Link
                  onClick={handleShow}
                  className="btn btn-enquiry"
                  activeClassName="active"
                >
                  Enquiry
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EnquiryForm />
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default Header;
