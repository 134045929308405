import {
  FiYoutube,
  FiLinkedin,
  FiInstagram,
  FiTwitter,
  FiPhone,
  FiMail,
} from "react-icons/fi";
import { RiTwitterXFill } from "react-icons/ri";

export default function Footer() {
  return (
    <div>
      <div className="main-footer">
        <div className="container">
          <div className="row">
            <div className="row">
              <div className="col-12 col-md-5">
                <p>© 2023 SZRegPharma. All Rights Reserved. </p>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-end align-items-center">
                <ul className="top-link">
                  <li>
                    <a
                      href="mailto:contact@szregpharma.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiMail /> &nbsp; <span>contact@szregpharma.com</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:szregpharma@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiMail /> &nbsp; <span>szregpharma@gmail.com</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/szreg-pharma-a81503263/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiLinkedin />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.youtube.com/@szregpharma"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiYoutube />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/szreg_pharma/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/SZReg_Pharma"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <RiTwitterXFill />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
